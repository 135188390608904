<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Project Name</th>
          <th>Project Code</th>
          <th>Party Name</th>
          <th>Business Line</th>
          <th>Warehouse</th>
          <th>Interval</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in projectList.data" :key="i">
          <td>{{ item.name }}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.party?.full_name }}</td>
          <td>{{ item.business?.name }}</td>
          <td>{{ item.business_location && item.business_location.name }}</td>
          <td>{{ item.interval }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <td>{{ item.status }}</td>
          <td>
            <span @click="handleEdit(item.id)" style="cursor: pointer">
              Edit
            </span>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!projectList?.data?.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListingTable",

  props: {
    projectList: {
      type: Object,
    },
  },

  methods: {
    handleEdit(projectId) {
      this.$router.push({
        name: 'project-edit',
        params: {
          ...this.$route.params,
          projectId
        },
        query: this.$route.query
      });
    },
  }
}
</script>
