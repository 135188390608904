<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Project List"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <DateQuerySetter @onClickGo="getProjects"/>
        </div>
      </div>
    </div>

    <ListTable :project-list="projectList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/project/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import {useStore} from "vuex";

const store = useStore();
const router = useRouter()
const route = useRoute()
const offset = ref(20);
let loading = ref(false);
let projectList = ref([]);
let {
  fetchProjectList,
} = handleProjects();

//computed
const companyId = computed(() => route.params.companyId);
const page = computed(() => route.query.page);

//methods
const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};
const resetPagination = () => {
    store.commit('resetPagination')
};


function onClickAddNewButton() {
  router.push({name: 'project-add', params: route.params, query: route.query})
}

async function getProjects() {
  loading.value = true;
  try {
    const res = await fetchProjectList(getQuery());
    if (res.status) {
      projectList.value = res.data;
      if (res.data.data.length > 0) {
          setPagination(res.data)
      }
    }else {
        projectList.value = []
        store.commit("resetPagination")
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false;
  }
}

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  if (page.value) query += '&page=' + page.value
  return query
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getProjects();
  }, 100)
}

onMounted(async () => {
  await getProjects();
})
</script>
